import Router from 'next/router';
import { UrlObject } from 'url';

const Context = (context: { res?: any } = {}, target: UrlObject | string) => {
  if (context.res) {
    context.res.writeHead(303, { Location: target });
    context.res.end();
  } else {
    Router.replace(target);
  }
};

export default Context;