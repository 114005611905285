import React from 'react';
import { connect, useDispatch } from 'react-redux';
import WindowResizeListener from 'react-window-size-listener';
import { Debounce } from 'react-throttle';

import { Loading, Alert } from '../../../components';

import appActions from '../../../redux/app/actions';

import { themeConfig } from '../../../config';
import { ThemeProvider } from '@l_ine/core';

import { useTypedSelector } from '../../../hooks/useTypeSelector';

const Layout: React.FC = ({ children }) => {
  const { height, loading } = useTypedSelector((state) => state.App);
  const dispatch = useDispatch();

  if (height === 0) {
    return (
      <div>
        <Debounce time="1000" handler="onResize">
          <WindowResizeListener
            onResize={(windowSize) =>
              dispatch(
                appActions.toggleAll(
                  windowSize.windowWidth,
                  windowSize.windowHeight
                )
              )
            }
          />
        </Debounce>
      </div>
    );
  }

  return (
    <div>
      <header>
        <ThemeProvider theme={themeConfig.current}>
          <>
            <Alert />
            {loading && <Loading />}
            {children}
          </>
        </ThemeProvider>
      </header>
    </div>
  );
};

export default Layout;
